import { createApp } from "vue";
import ContentPreviewMode from "../components/ContentPreviewMode.vue";
import { createSingleton, deepAssign, deepDelete, defu, mergeDraft, StudioConfigFiles } from "../utils/index.js";
import { useContentStorage } from "./useContentStorage.js";
import { callWithNuxt } from "#app";
import { useAppConfig, useNuxtApp, useRuntimeConfig, useContentState, ref, toRaw, useRoute, useRouter } from "#imports";
const useDefaultAppConfig = createSingleton(() => JSON.parse(JSON.stringify(useAppConfig())));
let dbFiles = [];
export const useStudio = () => {
  const nuxtApp = useNuxtApp();
  const { storage, findContentItem, updateContentItem, removeContentItem, removeAllContentItems, setPreviewMetaItems } = useContentStorage();
  const { studio: studioConfig, content: contentConfig } = useRuntimeConfig().public;
  const apiURL = window.sessionStorage.getItem("previewAPI") || studioConfig?.apiURL;
  const initialAppConfig = useDefaultAppConfig();
  const syncPreviewFiles = async (files) => {
    const previewToken = window.sessionStorage.getItem("previewToken");
    removeAllContentItems(previewToken);
    setPreviewMetaItems(previewToken, files);
    await Promise.all(
      files.map((file) => {
        updateContentItem(previewToken, file);
      })
    );
  };
  const syncPreviewAppConfig = (appConfig) => {
    const _appConfig = callWithNuxt(nuxtApp, useAppConfig);
    if (_appConfig?.ui) {
      _appConfig.ui.icons = { ..._appConfig.ui.icons, dynamic: true };
    }
    deepAssign(_appConfig, defu(appConfig, initialAppConfig));
    if (!appConfig) {
      deepDelete(_appConfig, initialAppConfig);
    }
  };
  const syncPreview = async (data) => {
    dbFiles = data.files = data.files || dbFiles || [];
    if (!storage.value) {
      return false;
    }
    dbFiles = [];
    const mergedFiles = mergeDraft(data.files, data.additions, data.deletions);
    const contentFiles = mergedFiles.filter((item) => ![StudioConfigFiles.appConfig, StudioConfigFiles.appConfigV4, StudioConfigFiles.nuxtConfig].includes(item.path));
    await syncPreviewFiles(contentFiles);
    const appConfig = mergedFiles.find((item) => [StudioConfigFiles.appConfig, StudioConfigFiles.appConfigV4].includes(item.path));
    syncPreviewAppConfig(appConfig?.parsed);
    requestRerender();
    return true;
  };
  const requestPreviewSynchronization = async () => {
    const previewToken = window.sessionStorage.getItem("previewToken");
    await $fetch("api/projects/preview/sync", {
      baseURL: apiURL,
      method: "POST",
      params: {
        token: previewToken
      }
    });
  };
  const mountPreviewUI = () => {
    const previewToken = window.sessionStorage.getItem("previewToken");
    const el = document.createElement("div");
    el.id = "__nuxt_preview_wrapper";
    document.body.appendChild(el);
    createApp(ContentPreviewMode, {
      previewToken,
      apiURL,
      syncPreview,
      requestPreviewSyncAPI: requestPreviewSynchronization
    }).mount(el);
  };
  const requestRerender = async () => {
    if (contentConfig?.documentDriven) {
      const { pages } = callWithNuxt(nuxtApp, useContentState);
      const contents = await Promise.all(Object.keys(pages.value).map(async (key) => {
        return await findContentItem(pages.value[key]?._id ?? key);
      }));
      pages.value = contents.reduce((acc, item, index) => {
        if (item) {
          acc[Object.keys(pages.value)[index]] = item;
        }
        return acc;
      }, {});
    }
    await nuxtApp.hooks.callHookParallel("app:data:refresh");
  };
  return {
    mountPreviewUI,
    initiateIframeCommunication
  };
  function initiateIframeCommunication() {
    if (!window.parent || window.self === window.parent) {
      return;
    }
    const router = useRouter();
    const route = useRoute();
    const editorSelectedPath = ref("");
    const routePayload = (route2) => ({
      path: route2.path,
      query: toRaw(route2.query),
      params: toRaw(route2.params),
      fullPath: route2.fullPath,
      meta: toRaw(route2.meta)
    });
    window.addEventListener("keydown", (e) => {
      if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey) {
        window.parent.postMessage({
          type: "nuxt-studio:preview:keydown",
          payload: {
            key: e.key,
            metaKey: e.metaKey,
            ctrlKey: e.ctrlKey,
            shiftKey: e.shiftKey,
            altKey: e.altKey
          }
        }, "*");
      }
    });
    window.addEventListener("message", async (e) => {
      const allowedOrigins = studioConfig?.iframeMessagingAllowedOrigins?.split(",").map((origin) => origin.trim()) || [];
      if (!["https://nuxt.studio", "https://new.nuxt.studio", "https://new.dev.nuxt.studio", "https://dev.nuxt.studio", "http://localhost:3000", ...allowedOrigins].includes(e.origin)) {
        return;
      }
      const { type, payload = {} } = e.data || {};
      switch (type) {
        case "nuxt-studio:editor:file-selected": {
          const content = await findContentItem(payload.path);
          if (!content || content._partial) {
            return;
          }
          if (!String(payload.path).endsWith(".md")) {
            const resolvedRoute = router.resolve(content._path);
            if (!resolvedRoute || !resolvedRoute.matched || resolvedRoute.matched.length === 0) {
              return;
            }
          }
          if (content._path !== useRoute().path) {
            editorSelectedPath.value = content._path;
            router.push(content._path);
          }
          break;
        }
        case "nuxt-studio:editor:media-changed":
        case "nuxt-studio:editor:file-changed": {
          const previewToken = window.sessionStorage.getItem("previewToken");
          const { additions = [], deletions = [] } = payload;
          for (const addition of additions) {
            await updateContentItem(previewToken, addition);
          }
          for (const deletion of deletions) {
            await removeContentItem(previewToken, deletion.path);
          }
          requestRerender();
          break;
        }
        case "nuxt-studio:config:file-changed": {
          const { additions = [], deletions = [] } = payload;
          const appConfig = additions.find((item) => [StudioConfigFiles.appConfig, StudioConfigFiles.appConfigV4].includes(item.path));
          if (appConfig) {
            syncPreviewAppConfig(appConfig?.parsed);
          }
          const shouldRemoveAppConfig = deletions.find((item) => [StudioConfigFiles.appConfig, StudioConfigFiles.appConfigV4].includes(item.path));
          if (shouldRemoveAppConfig) {
            syncPreviewAppConfig(void 0);
          }
        }
      }
    });
    nuxtApp.hook("page:finish", () => {
      detectRenderedContents();
      if (nuxtApp.payload.prerenderedAt) {
        requestRerender();
      }
    });
    nuxtApp.hook("content:document-driven:finish", ({ route: route2, page }) => {
      route2.meta.studio_page_contentId = page?._id;
    });
    nuxtApp.hook("nuxt-studio:preview:ready", () => {
      window.parent.postMessage({
        type: "nuxt-studio:preview:ready",
        payload: routePayload(useRoute())
      }, "*");
      setTimeout(() => {
        detectRenderedContents();
      }, 100);
    });
    function detectRenderedContents() {
      const renderedContents = Array.from(window.document.querySelectorAll("[data-content-id]")).map((el) => el.getAttribute("data-content-id"));
      const contentIds = Array.from(/* @__PURE__ */ new Set([route.meta.studio_page_contentId, ...renderedContents])).filter(Boolean);
      if (editorSelectedPath.value === contentIds[0]) {
        editorSelectedPath.value = "";
        return;
      }
      window.openContentInStudioEditor(contentIds, { navigate: true, pageContentId: route.meta.studio_page_contentId });
    }
    window.openContentInStudioEditor = (contentIds, data = {}) => {
      window.parent.postMessage({
        type: "nuxt-studio:preview:navigate",
        payload: {
          ...routePayload(route),
          contentIds,
          ...data
        }
      }, "*");
    };
  }
};
