import { useNuxtApp, useState, queryContent } from "#imports";
export const useContentStorage = () => {
  const nuxtApp = useNuxtApp();
  const contentPathMap = {};
  const storage = useState("studio-client-db", () => null);
  if (!storage.value) {
    nuxtApp.hook("content:storage", (_storage) => {
      storage.value = _storage;
    });
    queryContent("/non-existing-path").findOne();
  }
  const findContentItem = async (path) => {
    const previewToken = window.sessionStorage.getItem("previewToken");
    if (!path) {
      return null;
    }
    path = path.replace(/\/$/, "");
    let content = await storage.value?.getItem(`${previewToken}:${path}`);
    if (!content) {
      content = await storage.value?.getItem(`cached:${path}`);
    }
    if (!content) {
      content = content = await storage.value?.getItem(path);
    }
    if (!content) {
      content = contentPathMap[path || "/"];
    }
    return content;
  };
  const updateContentItem = (previewToken, file) => {
    if (!storage.value) return;
    contentPathMap[file.parsed._path] = file.parsed;
    storage.value.setItem(`${previewToken}:${file.parsed?._id}`, JSON.stringify(file.parsed));
  };
  const removeContentItem = async (previewToken, path) => {
    const content = await findContentItem(path);
    await storage.value?.removeItem(`${previewToken}:${path}`);
    if (content) {
      delete contentPathMap[content._path];
      const nonDraftContent = await findContentItem(content._id);
      if (nonDraftContent) {
        contentPathMap[nonDraftContent._path] = nonDraftContent;
      }
    }
  };
  const removeAllContentItems = async (previewToken) => {
    const keys = await storage.value.getKeys(`${previewToken}:`);
    await Promise.all(keys.map((key) => storage.value.removeItem(key)));
  };
  const setPreviewMetaItems = async (previewToken, files) => {
    const sources = new Set(files.map((file) => file.parsed._id.split(":").shift()));
    await storage.value.setItem(`${previewToken}$`, JSON.stringify({ ignoreSources: Array.from(sources) }));
  };
  return {
    storage,
    findContentItem,
    updateContentItem,
    removeContentItem,
    removeAllContentItems,
    setPreviewMetaItems
  };
};
