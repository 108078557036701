import { createDefu } from "defu";
export * from "./files.js";
export const StudioConfigFiles = {
  appConfig: "app.config.ts",
  appConfigV4: "app/app.config.ts",
  nuxtConfig: "nuxt.config.ts"
};
export const defu = createDefu((obj, key, value) => {
  if (Array.isArray(obj[key]) && Array.isArray(value)) {
    obj[key] = value;
    return true;
  }
});
export const createSingleton = (fn) => {
  let instance;
  return (_args) => {
    if (!instance) {
      instance = fn();
    }
    return instance;
  };
};
export function deepDelete(obj, newObj) {
  for (const key in obj) {
    const val = newObj[key];
    if (!(key in newObj)) {
      delete obj[key];
    }
    if (val !== null && typeof val === "object") {
      deepDelete(obj[key], newObj[key]);
    }
  }
}
export function deepAssign(obj, newObj) {
  for (const key in newObj) {
    const val = newObj[key];
    if (val !== null && typeof val === "object") {
      if (Array.isArray(val) && Array.isArray(obj[key])) {
        obj[key] = val;
      } else {
        obj[key] = obj[key] || {};
        deepAssign(obj[key], val);
      }
    } else {
      obj[key] = val;
    }
  }
}
